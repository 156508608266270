import React, { useEffect, useState } from "react";
import "./SignUp.css";
import Forum from "../Forum/Forum";
import Left from "../Left/Left";
import backgroundImage from "./background2.webp";
import logo from './logo.png';
import Success from  '../Success/Success';
import mixpanel from 'mixpanel-browser';
mixpanel.init('30cf63c9d6ff58a56143d83b41be1473');

let loadOnylOnce = false
let sendPageLoadOnce = false
export default function SignUp() {
  const [success, setSuccess] = useState(true);
  const [showSignUp, setShowSignUp] = useState(false);

  useEffect(() => {
    if (!loadOnylOnce) {
      loadOnylOnce = true
      window.postMessage(
        {
          type: "from_website",
          message: "get_session",
        },
        "*"
      );

      window.addEventListener("message", (event) => {
        if (
          event.source === window &&
          event.data.type &&
          event.data.type === "from_extension"
        ) {
          const data = event && event.data ? event.data : {}
          const isLogin = data.isLogin 
          const mixpanelDistinctId = data.mixpanelDistinctId 

          if (!isLogin) {
            setSuccess(false);
            setShowSignUp(true);
          }

          if (data.mixpanelDistinctId) {
            localStorage.mixpanelDistinctId = mixpanelDistinctId
            mixpanel.identify(mixpanelDistinctId)
            if (!sendPageLoadOnce) {
              sendPageLoadOnce = true;
              trackEvent('Open signup');
            }
          }
          setShowSignUp(!isLogin)
          
          
        }
      }, '*');
    }
      
  }, []);

  const trackEvent = (event) => {
    event = `Signup website - ${event}`

    if (localStorage.mixpanelDistinctId) {
      console.log(event)
      mixpanel.track(event, {
        distinct_id: localStorage.mixpanelDistinctId
      })
    }
  }

  return (
    <div className="SignUp">
      <img src={backgroundImage} alt="" className="SignUp_image" />
      <img src={logo} alt="" className="SignUp_logo" />
      {!success && showSignUp ? (
        <>
          <Forum trackEvent={trackEvent} setSuccess={setSuccess} />
          <Left />
        </>
      ) : (
        <Success />
      )}
    </div>
  );
}
