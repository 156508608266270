import React, { useState } from "react";
import "./Forum.css";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress"; // Added CircularProgress for loading

export default function Forum(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(""); // Added state for second password field
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userExistsError, setUserExistsError] = useState(false);
  const [loading, setLoading] = useState(false); // Added state for loading
  const [passwordMatchError, setPasswordMatchError] = useState(false); // Added state for password match error
  const [secondEmail, setSecondEmail] = useState(""); // Added state for second email
  const [secondEmailError, setSecondEmailError] = useState(false); // Added state for second email error
  const [emailMismatchError, setEmailMismatchError] = useState(false); // Added state for email mismatch error

  const handleEnterKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSignup();
    }
  };

  const buildUrl = () => {
    const env = process.env.NODE_ENV || "development";

    if (env === "development") {
      return "http://localhost:3000";
    }

    return "https://api.jinno.app";
  };

  const handleSignup = async () => {
    props.trackEvent('Click on signup')
    let valid = true;

    setEmailError(false);
    setSecondEmailError(false);
    setPasswordError(false);
    setPasswordMatchError(false);
    setEmailMismatchError(false);

    if (!email.includes("@")) {
      setEmailError(true);
      valid = false;
    }

    if (!secondEmail.includes("@")) {
      setSecondEmailError(true);
      valid = false;
    }

    if (email !== secondEmail) {
      setEmailMismatchError(true);
      valid = false;
    }

    if (password.length < 8) {
      setPasswordError(true);
      valid = false;
    }

    if (password !== confirmPassword) {
      setPasswordMatchError(true);
      valid = false;
    }

    if (!valid) {
      return;
    }

    setLoading(true); // Set loading to true while API call is in progress

    if (valid) {
      const data = await api({
        email: email ? email.trim() : null,
        secondEmail: secondEmail ? secondEmail.trim() : null,
        password: password ? password.trim() : null
      });
      setLoading(false); // Set loading back to false after API call is finished

      if (data.error === "User already exists") {
        props.trackEvent('Signup server error')

        setUserExistsError(true);
        return;
      }

      if (data.success) {
        props.trackEvent('Signup success')

        let email;
        let session;
        if (data.success.email) {
          email = data.success.email;
        }
        if (data.success.session) {
          session = data.success.session;
        }
        
        props.setSuccess(true);
        window.postMessage(
          {
            type: "from_website",
            message: "login",
            email: email,
            session: session,
          },
          "*"
        );
      }
    }
  };

  const api = async (data) => {
    try {
      const response = await fetch(buildUrl() + "/api/userInfo/register", {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
      return response.json();
    } catch (e) {}
  };

  return (
    <div className="right" style={{ marginTop: "30px" }}>
      <div className="title">Sign Up To Jinno</div>
      <input
        type="text"
        placeholder="Email"
        className="input"
        onKeyDown={handleEnterKeyPress}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      {emailError && <div className="error">Invalid email address</div>}
      <input
        type="text"
        placeholder="Confirm your email"
        className="input"
        onKeyDown={handleEnterKeyPress}
        value={secondEmail}
        onChange={(e) => setSecondEmail(e.target.value)}
      />
      {secondEmailError && <div className="error">Invalid second email address</div>}
      {emailMismatchError && <div className="error">Emails do not match</div>}
      <input
        type="password"
        value={password}
        onKeyDown={handleEnterKeyPress}
        placeholder="Password"
        className="input"
        onChange={(e) => setPassword(e.target.value)}
      />
      {passwordError && (
        <div className="error">Password must be at least 8 characters</div>
      )}
      <input
        type="password"
        value={confirmPassword}
        onKeyDown={handleEnterKeyPress}
        placeholder="Confirm Password"
        className="input"
        onChange={(e) => setConfirmPassword(e.target.value)}
      />
      {passwordMatchError && (
        <div className="error">Passwords do not match</div>
      )}
      <div style={{ width: "300px" }}>
        <Button variant="contained" className="button" onClick={handleSignup}>
          {loading ? <CircularProgress size={24} color="primary" /> : "Signup"}
        </Button>
      </div>
      {userExistsError && (
        <div className="error">User already exists. Please login instead.</div>
      )}
    </div>
  );
}