import React from "react";
import Button from "@mui/material/Button";

export default function Success() {

  const clickOnLink = () => {
    window.open('https://marketplace.visualstudio.com/items?itemName=jinno.codelens-sample', '_blank')
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        fontSize: "36px",
        fontWeight: "bold",
        textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
        width: "100%",
        height: "100vh"
      }}
    >
      <div style={{ textAlign: "center" }}>Signup success. <br />Follow these steps to use Jinno:</div>
      <div style={{ marginTop: '30px', fontSize: '24px', textAlign: 'center' }}>
        <p>Step 1: Install the VS Code extension</p>
        <Button variant="contained" color="primary" onClick={clickOnLink} style={{ margin: '10px 0' }}>
          Install VS Code Extension
        </Button>
        <p>Step 2: Run your React project and open the Jinno Chrome extension</p>
      </div>
    </div>
  );
}