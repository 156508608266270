import React, { useEffect, useRef } from "react";
import "./Left.css";

export default function Left() {
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframe = iframeRef.current;
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      "*"
    );
    iframe.contentWindow.postMessage(
      '{"event":"command","func":"setPlaybackQuality","args":["hd760"]}',
      "*"
    );
  }, []);

  return (
    <div className="Left">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
          width="760"
          height="415"
          className="youtube"
          src="https://www.youtube.com/embed/Gyk5NWBdpo4?si=BLxItsgdtHk-LxAU&autoplay=1&vq=hd720"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          ref={iframeRef}
        ></iframe>
      </div>
    </div>
  );
};
